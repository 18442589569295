header {
  background-color: white;
  box-shadow: 0px 20px 20px -23px rgba(0, 0, 0, 0.514);
}

.logo-sva {
  max-width: 100%;
}

h3 {
  font-size: 1.10rem;
}

hr {
  margin-left: auto;
  margin-right: auto;
}

.section-header {
  background: linear-gradient(90deg, #f0f0f0 0%, rgba(255, 255, 255, 1) 25%, rgba(255, 255, 255, 1) 75%, #f0f0f0 100%);
}

.title {
  color: #dd55a2;
}

#title {
  color: black;
}

.carousel {
  color: white;
}

.section-white {
  background-color: white;
  box-shadow: 0px 20px 20px -25px rgba(0, 0, 0, 0.514),
    0px -20px 20px -25px rgba(0, 0, 0, 0.514);
  position: relative;
  z-index: 1;
}

.section-grey {
  background-color: #f8f8f8;
}


.title-article {
  color: #dd55a2;
}

.subtitle {
  color: #8F0066;
  font-weight: bold;
}

.subtitle-pub {
  font-weight: bold;
}

.button-home {
  width: 200px;
  color: white;
  background-color: #dd55a2;
  border: none;
  font-size: 18px;
  font-weight: 400;
  border-radius: 5px;
  padding: 5px 8px;
  transition: 0.3s;
}

.button-home:hover {
  background-color: #e03094;
}

.link {
  color: #dd55a2;
  font-weight: bold;
  text-decoration: none;
}

.link:hover {
  cursor: pointer;
}

.link-pub {
  color: #df3688;
  font-weight: bold;
  text-decoration: none;
}

ul {
  padding: 0;
}

li {
  list-style-type: none;
}

.card-hostesse {
  background-color: #f7f7f7;
  box-shadow: 0px 0px 8px grey;
  transition: 0.3s;
}

.card-hostesse-profil {
  background-color: #f7f7f7;
  box-shadow: 0px 0px 8px grey;
  transition: 0.3s;
}

.card-hostesse:hover {
  transform: scale(1.05);
}

.card-hostesse-name {
  color: #dd55a2;
  font-weight: bolder;
}

.card-container-photo {
  background:
    linear-gradient(#dd55a2, #8F0066) top,
    linear-gradient(#f7f7f7, #f7f7f7) bottom;
  background-size: 100% 60%;
  background-repeat: no-repeat;
}

.card-photo {
  border-radius: 50%;
  border: solid 6px #8F0066;
  max-width: 425px;
  width: 100%;
}

.card-photo:hover {
  cursor: pointer;
}

.container-profil {
  max-width: 500px;
  margin: auto;
}

.photo-profil {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
  max-width: 700px;
  width: 0%;
  transition: width 0.5s !important;
  -webkit-transition: width 0.5s !important;
  transition-delay: 0s !important;
}

.photo-profil:hover {
  cursor: pointer;
}

.section-pub {
  background: linear-gradient(0deg, #EA1F81 0%, rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 1) 90%, #EA1F81 100%);
}

#neon-title {
  color: #ff92c6 !important;
  animation: flicker 1.5s infinite alternate;
}

@keyframes flicker {

  0%,
  18%,
  22%,
  25%,
  53%,
  57%,
  100% {

    text-shadow:
      0 0 7px #da0069,
      0 0 10px #da0069,
      0 0 21px #da0069,
      0 0 42px #da0069,
      0 0 151px #da0069;
  }

  20%,
  24%,
  55% {
    text-shadow: none;
  }
}

.link-to-call {
  background-color: #dd55a2;
  color: white;
  border: none;
  padding: 8px 36px;
  border-radius: 5px;
  transition: 0.5s;
}

.link-to-call:hover {
  background-color: #bd4689;
}

.link-to-site {
  background-color: #df3688;
  color: white;
  border: none;
  padding: 8px 36px;
  border-radius: 5px;
  transition: 0.5s;
}

.link-to-site:hover {
  background-color: #ce347e;
}

.info-price {
  font-size: 12px;
}

#button-to-scroll-up {
  background-color: #dd55a2;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  border: none;
  position: fixed;
  z-index: 1;
  bottom: 10px;
  right: 10px;
  transition: 0.3s;
}

#button-to-scroll-up:hover {
  background-color: #bd4689;
}

#icon-up {
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
}

footer {
  margin-bottom: 0;
  background-color: #ffffff;
  box-shadow: 0px -20px 20px -25px rgba(0, 0, 0, 0.514);
}

.link-footer {
  cursor: pointer;
  color: #df3688;
  text-decoration: none;
  font-weight: 500;
}

.not-found {
  color: #8F0066;
}

.link-home {
  background-color: #8F0066;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  padding: 10px;
  text-decoration: none;
}

.cookies-banner-container {
  z-index: 1;
}

.cookies-banner {
  font-size: 12px;
  z-index: 1;
  max-width: 768px;
  box-shadow: 0px 0px 8px grey;
}

.accept-cookies-button {
  background-color: #dd55a2;
  color: #ffffff;
}

.decline-cookies-button {
  background-color: #ececec;
}

.button-cookies {
  background-color: #dd55a2;
  height: 36px;
  width: 36px;
  border-radius: 35% 65%;
  border: none;
  z-index: 1;
  transition: 0.3s;
  margin-bottom: 2.5rem;
  margin-left: 1rem;
}

.icon-cookies {
  color: #ffffff;
  font-size: 22px;
  font-weight: bold;
}

@media only screen and (min-width: 576px) {
  .logo-sva {
    max-width: 72%;
  }
}

@media only screen and (min-width: 768px) {
  .logo-sva {
    max-width: 100%;
  }

  .cookies-banner {
    border-radius: 10px;
    font-size: 13px;
    width: 420px;
    margin-bottom: 2.5rem;
    margin-left: 1rem;
  }

  .decline-cookies-button {
    border-bottom-left-radius: 10px;
  }

  .accept-cookies-button {
    border-bottom-right-radius: 10px;
  }
}

@media only screen and (min-width: 1400px) {
  .logo-sva {
    max-width: 72%;
  }
}